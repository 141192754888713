<template>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
  />
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
  />

  <div class="overflow-hidden">
    <section class="pt-0 md:pt-10 pb-20 pt-10 base-100">
      <div class="container px-4 mx-auto base-100 md:mt-60">
        <div
          class="text-center max-w-2xl mx-auto mt-10 mb-12 md:mt-20 md:mb-24"
        >
          <h2
            class="mb-4 text-3xl base-100 text-base-content md:text-5xl font-bold font-heading text-center tracking-px-n leading-tight"
          >
            Sparkling Clean, <span class="italic">Every </span>
            <span class="italic">Time</span>:
            <span class="font-normal">Your Trusted Cleaning Partner</span>
          </h2>

          <h2 class="mb-6 text-2xl text-base-content mt-2 font-medium">
            Book your Scrubly expert now <br />by entering your
            <strong>postcode</strong>
          </h2>
          <div
            class="mb-6 bg-neutral rounded-lg md:rounded-full p-4 flex flex-col md:flex-row md:items-center gap-3 w-full max-w-lg mx-auto"
          >
            <input
              type="text"
              id="postcode"
              v-model="postcode"
              class="border text-base-content rounded-md px-4 py-3 w-full md:w-52 md:flex-1 md:rounded-l-full md:rounded-r-none"
              placeholder="E.g. AB1 2CD"
            />
            <button
              @click="checkPostcode"
              class="bg-primary hover:bg-secondary text-neutral-content font-semibold text-white rounded-md px-5 py-3 w-full md:w-auto md:ml-0 md:rounded-r-full md:rounded-l-none mt-3 md:mt-0"
            >
              Check
            </button>
          </div>

          <div
            v-if="showAlert"
            class="bg-primary border md:rounded-full border-error-content text-error-content px-5 py-4 rounded-lg relative animate__animated animate__fadeIn"
            role="alert"
          >
            <strong class="font-bold text-white">Oops! </strong>
            <span class="block text-white sm:inline warning-content">{{
              alertMessage
            }}</span>
            <span class="absolute top-1/2 -mt-3 right-4">
              <svg
                @click="showAlert = false"
                class="fill-current h-6 w-6 text-white hover:text-base-content cursor-pointer"
                role="button"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <title>Close</title>
                <path
                  d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </section>
  </div>
  <section class="">
    <div class="container px-4 py-12 mx-auto">
      <div class="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
        <!-- Card 1 -->
        <div
          class="bg-white p-6 rounded-3xl shadow-lg text-center border-2 border-gray-700"
        >
          <svg
            width="256px"
            height="256px"
            class="w-12 h-12 mx-auto mb-4"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <rect
                x="3"
                y="3"
                width="18"
                height="18"
                stroke="#000000"
                stroke-width="1.5"
              ></rect>
              <path
                d="M12 6V12"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M16.24 16.24L12 12"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </g>
          </svg>
          <h1 class="text-xl font-semibold text-base-content">
            24/7 Availability
          </h1>
          <p class="mt-2 text-base-content">
            Pick a time that suits you, you can also book just 4 hours in
            advance
          </p>
        </div>

        <!-- Card 2 -->
        <div
          class="bg-white p-6 rounded-3xl shadow-lg text-center border-2 border-gray-700"
        >
          <svg
            version="1.0"
            id="Layer_1"
            class="w-12 h-12 mx-auto mb-4"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 64 64"
            enable-background="new 0 0 64 64"
            xml:space="preserve"
            fill="#000000"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <g>
                <path
                  fill="#000000"
                  d="M63.652,14.49c-0.277-1.584-1.283-1.544-2.209-0.618s-9.787,9.786-9.787,9.786c-3.123,3.123-8.189,3.123-11.312-0.001c-3.125-3.124-3.125-8.188,0-11.313c0,0,8.963-8.963,9.787-9.786c0.822-0.823,0.9-1.901-0.621-2.21C48.375,0.117,47.201,0,46,0c-9.941,0-18,8.06-18,18s8.059,18,18,18s18-8.06,18-18C64,16.799,63.852,15.631,63.652,14.49z"
                ></path>
                <path
                  fill="#000000"
                  d="M2.342,50.344c-3.123,3.123-3.123,8.189,0.001,11.313s8.19,3.124,11.313,0.001l7.794-7.794L10.136,42.55L2.342,50.344z M8,59c-1.656,0-3-1.344-3-3s1.344-3,3-3s3,1.344,3,3S9.657,59,8,59z"
                ></path>
                <path
                  fill="#000000"
                  d="M27.393,25.293L11.55,41.136L22.865,52.45l15.842-15.843C33.535,34.578,29.422,30.466,27.393,25.293z"
                ></path>
              </g>
            </g>
          </svg>
          <h1 class="text-xl font-semibold text-base-content">
            Custom Cleaning Options
          </h1>
          <p class="mt-2 text-base-content">
            You can choose which rooms you wish us to clean and book only the
            services you need
          </p>
        </div>

        <!-- Card 3 -->
        <div
          class="bg-white p-6 rounded-3xl shadow-lg text-center border-2 border-gray-700"
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="w-12 h-12 mx-auto mb-4"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M3 8C3 6.34315 4.34315 5 6 5H18C19.6569 5 21 6.34315 21 8V16C21 17.6569 19.6569 19 18 19H6C4.34315 19 3 17.6569 3 16V8Z"
                stroke="#000000"
                stroke-width="2"
              ></path>
              <path d="M3 10H21" stroke="#000000" stroke-width="2"></path>
              <path
                d="M14 15L17 15"
                stroke="#000000"
                stroke-width="2"
                stroke-linecap="round"
              ></path>
            </g>
          </svg>
          <h1 class="text-xl font-semibold text-base-content">Pay as you Go</h1>
          <p class="mt-2 text-base-content">
            We charge clients only for the actual time a cleaner spends at your
            property
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PostcodeForm",
  data() {
    return {
      postcode: "",
      showAlert: false,
      alertMessage: "",
      validPostcodes: ["E17", "E11", "E10", "UB8", "E6"],
    };
  },
  methods: {
    async checkPostcode() {
      try {
        const response = await fetch(
          `https://api.postcodes.io/postcodes/${this.postcode}/validate`
        );
        const result = await response.json();

        if (result.result === true) {
          const postcodePrefix = this.postcode.substring(0, 3).toUpperCase();
          const postcodeIsInArea = this.validPostcodes.includes(postcodePrefix);

          if (postcodeIsInArea) {
            this.$router.push({
              name: "submit-information",
              params: { postcode: this.postcode },
            });
          } else {
            this.alertMessage = "Sorry, we do not operate in your area.";
            this.showAlert = true;
          }
        } else {
          this.alertMessage =
            "Invalid UK postcode. Please enter a valid postcode.";
          this.showAlert = true;
        }
      } catch (error) {
        console.error(error);
        this.alertMessage = "An error occurred. Please try again later.";
        this.showAlert = true;
      }
    },
  },
};
</script>
