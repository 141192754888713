<template>
  <div class="drawer lg:drawer-open">
    <input id="my-drawer-2" type="checkbox" class="drawer-toggle" />
    <div class="drawer-content flex flex-col">
      <!-- Navigation Bar -->
      <navBar />

      <!-- Main Content -->
      <div class="p-4 bg-base-100 mt-20">
        <h1 class="text-2xl font-bold">Hello, {{ user.firstName }}!</h1>
        <p class="mt-2 text-base-content">
          This is an example dashboard using Tailwind CSS and DaisyUI.
        </p>
      </div>
    </div>

    <!-- Sidebar -->
    <div class="drawer-side">
      <label for="my-drawer-2" class="drawer-overlay"></label>
      <ul class="menu p-4 w-80 min-h-full bg-base-200 text-base-content">
        <!-- Sidebar content here -->
        <li>
          <a class="text-lg hover:text-secondary hover:animate-pulse"
            >Sidebar Item 1</a
          >
        </li>
        <li>
          <a class="text-lg hover:text-secondary hover:animate-pulse"
            >Sidebar Item 2</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import navBar from "./nav/navBar";
import { ref, onMounted } from "vue";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

const user = ref({ firstName: "" });

onMounted(async () => {
  const auth = getAuth();
  onAuthStateChanged(auth, async (userAuth) => {
    if (userAuth) {
      const firestore = getFirestore();
      const userDoc = await getDoc(doc(firestore, "users", userAuth.uid));
      if (userDoc.exists()) {
        user.value.firstName = userDoc.data().firstName;
      }
    }
  });
});
</script>

<style>
.size-10 {
  height: 2.5rem;
  width: 2.5rem;
}
.size-16 {
  height: 4rem;
  width: 4rem;
}
</style>
