<template>
  <div class="min-h-screen bg-base-200">
    <div
      class="bg-base-200 mx-auto max-w-screen-lg h-full px-10 py-60 sm:px-6 lg:px-8"
    >
      <div class="mx-auto max-w-lg text-center">
        <h1 class="text-2xl font-bold sm:text-3xl">Verify Your Email</h1>
        <p class="mt-4">Please verify your email address on your email.</p>
        <p class="text-lg font-semibold">{{ email }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getAuth, applyActionCode } from "firebase/auth";

const email = ref("");
const router = useRouter();
const route = useRoute();
const auth = getAuth();

onMounted(() => {
  email.value = route.query.email || "";

  // Apply action code to verify email
  const actionCode = route.query.oobCode;
  if (actionCode) {
    applyActionCode(auth, actionCode)
      .then(() => {
        // Email verified successfully, redirect to feed page
        router.push("/feed");
      })
      .catch((error) => {
        console.error(error);
        alert("Failed to verify email.");
      });
  }
});
</script>
