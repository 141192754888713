<template>
  <nav
    class="bg-base-content px-4 font-bold py-2.5 fixed w-full z-20 top-0 left-0 shadow-lg"
  >
    <div class="container flex items-center justify-between mx-auto">
      <router-link to="/" class="flex items-center">
        <div
          class="app-icon is-ios7 is-custom-size"
          draggable="2579"
          style="width: 206px; height: 50px"
        >
          <img
            src="@/assets/NAVLOGO.png"
            alt="Navigation Logo"
            loading="lazy"
            width="206"
            height="50"
            onload="this.setAttribute('lazy', 'loaded')"
            onerror="this.setAttribute('lazy', 'error')"
            lazy="loaded"
            style="width: 206px; height: 50px"
          />
        </div>
      </router-link>
      <div class="flex items-center space-x-6 md:space-x-8">
        <div class="hidden md:flex items-center">
          <ul class="flex flex-row space-x-6 text-white">
            <li>
              <router-link
                to="/services"
                class="text-lg hover:text-secondary hover:underline"
              >
                Services
              </router-link>
            </li>
            <li>
              <router-link
                to="/pricing"
                class="text-lg hover:text-secondary hover:underline"
              >
                Pricing
              </router-link>
            </li>
            <li>
              <router-link
                to="#"
                class="text-lg hover:text-secondary hover:underline"
              >
                Help
              </router-link>
            </li>
            <li v-if="isLoggedIn">
              <router-link
                to="/dashboard"
                class="text-lg hover:text-secondary hover:underline"
              >
                Create Booking
              </router-link>
            </li>
            <li v-else>
              <router-link
                to="/work"
                class="text-lg hover:text-secondary hover:underline"
              >
                Work
              </router-link>
            </li>
          </ul>
        </div>
        <div class="md:hidden">
          <button @click="toggleMenu" class="text-white focus:outline-none">
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
        </div>
        <div class="hidden md:block">
          <router-link v-if="!isLoggedIn" to="/signin">
            <button
              type="button"
              class="bg-primary text-white focus:ring-4 focus:outline-none font-medium rounded-full text-lg px-4 py-2 hover:bg-secondary hover:text-primary transition duration-300 ease-in-out"
            >
              Sign In
            </button>
          </router-link>
          <button
            v-if="isLoggedIn"
            @click="handleSignOut"
            type="button"
            class="bg-primary text-white focus:ring-4 focus:outline-none font-medium rounded-full text-lg px-4 py-2 hover:bg-secondary hover:text-primary transition duration-300 ease-in-out"
          >
            Sign Out
          </button>
        </div>
      </div>
    </div>
    <transition
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <div
        v-if="isMenuOpen"
        class="dropdown-menu md:hidden bg-base-content p-4 rounded-b-lg shadow-lg mt-2 right-0 absolute border-none border-t-0"
      >
        <ul class="flex flex-col space-y-4 text-white">
          <li>
            <router-link
              to="/services"
              class="text-lg hover:text-secondary hover:underline"
            >
              Services
            </router-link>
          </li>
          <li>
            <router-link
              to="/pricing"
              class="text-lg hover:text-secondary hover:underline"
            >
              Pricing
            </router-link>
          </li>
          <li>
            <router-link
              to="#"
              class="text-lg hover:text-secondary hover:underline"
            >
              Help
            </router-link>
          </li>
          <li v-if="isLoggedIn">
            <router-link
              to="/dashboard"
              class="text-lg hover:text-secondary hover:underline"
            >
              Create Booking
            </router-link>
          </li>
          <li v-else>
            <router-link
              to="/work"
              class="text-lg hover:text-secondary hover:underline"
            >
              Work
            </router-link>
          </li>
          <li v-if="!isLoggedIn">
            <router-link to="/signin">
              <button
                type="button"
                class="bg-primary text-white focus:ring-4 focus:outline-none font-medium rounded-full text-lg px-4 py-2 w-full text-left hover:bg-secondary hover:text-primary transition duration-300 ease-in-out"
              >
                Sign In
              </button>
            </router-link>
          </li>
          <li v-if="isLoggedIn">
            <button
              @click="handleSignOut"
              type="button"
              class="bg-primary text-white focus:ring-4 focus:outline-none font-medium rounded-full text-base sm:text-lg px-4 sm:px-6 py-2 sm:py-3 w-full text-left hover:bg-secondary hover:text-primary transition duration-300 ease-in-out"
            >
              Sign Out
            </button>
          </li>
        </ul>
      </div>
    </transition>
  </nav>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useRouter } from "vue-router";
import "animate.css";

export default {
  name: "navBar",
  setup() {
    const isLoggedIn = ref(false);
    const isMenuOpen = ref(false);
    const isScreenLarge = ref(window.innerWidth >= 768);
    const router = useRouter();
    const auth = getAuth();

    const checkScreenSize = () => {
      const screenIsLarge = window.innerWidth >= 768;
      if (screenIsLarge && !isScreenLarge.value) {
        isMenuOpen.value = false;
      }
      isScreenLarge.value = screenIsLarge;
    };

    onMounted(() => {
      onAuthStateChanged(auth, (user) => {
        isLoggedIn.value = !!user;
      });

      window.addEventListener("resize", checkScreenSize);
      checkScreenSize(); // Check initial screen size
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", checkScreenSize);
    });

    const handleSignOut = () => {
      signOut(auth)
        .then(() => {
          router.push("/");
        })
        .catch((error) => {
          console.error("Error signing out: ", error);
        });
    };

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };

    const beforeEnter = (el) => {
      if (!isScreenLarge.value) {
        el.style.display = "block";
        el.classList.add("animate__animated", "animate__slideInRight");
      }
    };

    const enter = (el, done) => {
      if (!isScreenLarge.value) {
        el.addEventListener("animationend", () => {
          el.classList.remove("animate__animated", "animate__slideInRight");
          done();
        });
      } else {
        done();
      }
    };

    const beforeLeave = (el) => {
      if (!isScreenLarge.value) {
        el.classList.add("animate__animated", "animate__slideOutRight");
      }
    };

    const leave = (el, done) => {
      if (!isScreenLarge.value) {
        el.addEventListener("animationend", () => {
          el.classList.remove("animate__animated", "animate__slideOutRight");
          el.style.display = "none";
          done();
        });
      } else {
        done();
      }
    };

    return {
      isLoggedIn,
      isMenuOpen,
      handleSignOut,
      toggleMenu,
      beforeEnter,
      enter,
      beforeLeave,
      leave,
    };
  },
};
</script>

<style scoped>
/* Ensuring the background color matches during animation */
.bg-base-content {
  background-color: #1a1a1a; /* Update this to match your navbar color */
}

.navbar-transition-enter-active,
.navbar-transition-leave-active {
  transition: opacity 0.5s;
}

.navbar-transition-enter, 
.navbar-transition-leave-to /* .navbar-transition-leave-active in <2.1.8 */ {
  opacity: 0;
}

/* Ensure dropdown menu stays on the right on small screens */
.dropdown-menu {
  right: 0;
}
</style>
