<template>
  <div></div>
</template>

<script>
import { ref, onMounted } from "vue";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useRouter } from "vue-router";

export default {
  name: "UserDashboard",
  setup() {
    const isLoggedIn = ref(false);
    const router = useRouter();
    const auth = getAuth();

    onMounted(() => {
      onAuthStateChanged(auth, (user) => {
        if (!user) {
          router.push("/");
        } else {
          isLoggedIn.value = true;
        }
      });
    });

    const handleSignOut = () => {
      signOut(auth)
        .then(() => {
          router.push("/");
        })
        .catch((error) => {
          console.error("Error signing out: ", error);
        });
    };

    return {
      isLoggedIn,
      handleSignOut,
    };
  },
};
</script>

<style scoped>
body {
  background-color: #f7fafc; /* Tailwind's bg-gray-100 */
}
</style>
