<template>
  <div class="min-h-screen bg-base-200">
    <div
      class="bg-base-200 mx-auto max-w-screen-lg h-full px-10 py-20 sm:px-6 lg:px-8"
    >
      <div class="mx-auto max-w-lg text-center">
        <h1 class="text-2xl font-bold sm:text-3xl">Sign in to your account.</h1>
      </div>

      <form
        @submit.prevent="register"
        class="mx-auto mt-8 mb-0 max-w-md space-y-4"
      >
        <div>
          <label for="email" class="sr-only">Email</label>
          <div class="relative">
            <input
              type="email"
              class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm"
              v-model="email"
              placeholder="Enter email"
            />
            <span
              class="absolute inset-y-0 right-0 grid place-content-center px-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                />
              </svg>
            </span>
          </div>
        </div>
        <div>
          <label for="password" class="sr-only">Password</label>
          <div class="relative">
            <input
              type="password"
              class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm"
              v-model="password"
              placeholder="Enter password"
            />
            <span
              class="absolute inset-y-0 right-0 grid place-content-center px-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                />
              </svg>
            </span>
          </div>
        </div>
        <div class="flex items-center justify-center">
          <button
            type="submit"
            class="object-left bg-primary hover:bg-secondary text-neutral-content font-semibold text-white rounded-md px-20 py-2 hover:animate-pulse"
          >
            Sign In
          </button>
        </div>
        <p class="text-sm text-gray-500">
          <router-link class="underline" to="/register"
            >register here</router-link
          >
        </p>
        <div class="flex items-center my-6">
          <hr class="flex-grow border-gray-300" />
          <span class="mx-4 text-gray-500">or sign in with</span>
          <hr class="flex-grow border-gray-300" />
        </div>
        <div class="flex items-center justify-center">
          <button
            @click.prevent="signInWithGoogle"
            class="bg-secondary hover:bg-secondary text-neutral-content font-semibold text-white rounded-md px-20 py-2 hover:animate-pulse flex items-center justify-center"
          >
            <svg
              class="mr-2 -ml-1 w-4 h-4"
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="google"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 488 512"
            >
              <path
                fill="currentColor"
                d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
              ></path>
            </svg>
            Google
          </button>
        </div>
      </form>
      <p v-if="errMsg" class="text-red-500 mt-4">{{ errMsg }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { useRouter } from "vue-router";

const email = ref("");
const password = ref("");
const router = useRouter();
const errMsg = ref();

const register = () => {
  const auth = getAuth();
  signInWithEmailAndPassword(auth, email.value, password.value)
    .then(() => {
      console.log("Successfully signed in");
      router.push("/feed");
    })
    .catch((error) => {
      console.error(error.code);
      switch (error.code) {
        case "auth/invalid-email":
          errMsg.value = "Invalid email";
          break;
        case "auth/invalid-credential":
          errMsg.value = "Invalid email or password";
          break;
        case "auth/user-not-found":
          errMsg.value = "No account with that email found";
          break;
        case "auth/wrong-password":
          errMsg.value = "Incorrect password";
          break;
        default:
          errMsg.value = "An error occurred. Please try again.";
          break;
      }
    });
};

const signInWithGoogle = () => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider)
    .then(() => {
      console.log("Successfully signed in with Google");
      router.push("/feed");
    })
    .catch((error) => {
      console.error(error.code);
      errMsg.value =
        "An error occurred during Google sign-in. Please try again.";
    });
};
</script>
