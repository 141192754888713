import { createApp } from "vue";
import App from "./App.vue";
import "./assets/tailwind.css";
import { createRouter, createWebHistory } from "vue-router";
import "@vuepic/vue-datepicker/dist/main.css";
import SubmitInformation from "./components/SubmitInformation.vue";

import SignIn from "./components/SignIn.vue";
import PricingPage from "./components/PricingPage.vue";
import ServicesPage from "./components/ServicesPage.vue";
import RegisterUser from "./components/RegisterUser.vue";
import FeedHome from "./components/FeedHome.vue";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import UserDashboard from "./components/UserDashboard.vue";
import EmailVerification from "./components/EmailVerification.vue";
import homePage from "./components/homePage.vue";

const firebaseConfig = {
  apiKey: "AIzaSyDqmxYWsJuXBsxBirjDAEiybmD_Wrjik8c",
  authDomain: "scrub-d149b.firebaseapp.com",
  projectId: "scrub-d149b",
  storageBucket: "scrub-d149b.appspot.com",
  messagingSenderId: "432799573696",
  appId: "1:432799573696:web:82a0427eb90d44d5846f25",
};

initializeApp(firebaseConfig);

const routes = [
  { path: "/", component: homePage },
  { path: "/signin", component: SignIn },
  { path: "/register", component: RegisterUser },
  { path: "/pricing", component: PricingPage },
  { path: "/services", component: ServicesPage },
  { path: "/dashboard", component: UserDashboard },
  {
    path: "/submit-information/:postcode",
    name: "submit-information",
    props: true,
    component: SubmitInformation,
  },
  {
    path: "/verify-email",
    name: "EmailVerification",
    component: EmailVerification,
  },
  {
    path: "/feed",
    component: FeedHome,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const removeListener = onAuthStateChanged(
      getAuth(),
      (user) => {
        removeListener();
        resolve(user);
      },
      reject
    );
  });
};

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (await getCurrentUser()) {
      next();
    } else {
      alert("You don't have access!");
      next("/signin");
    }
  } else {
    next();
  }
});

const app = createApp(App);

app.use(router);

app.mount("#app");
