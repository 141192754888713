<template>
  <div class="min-h-screen bg-base-200">
    <div
      class="bg-base-200 mx-auto max-w-screen-lg h-full px-10 py-60 sm:px-6 lg:px-8"
    >
      <div class="mx-auto max-w-lg text-center">
        <h1 class="text-2xl font-bold sm:text-3xl">Register a new account.</h1>
      </div>

      <form
        @submit.prevent="register"
        class="mx-auto mt-8 mb-0 max-w-md space-y-4"
      >
        <div class="flex gap-4">
          <div class="w-1/2">
            <label for="first-name" class="sr-only">First Name</label>
            <div class="relative">
              <input
                type="text"
                class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm"
                v-model="firstName"
                placeholder="First Name"
              />
            </div>
          </div>
          <div class="w-1/2">
            <label for="last-name" class="sr-only">Last Name</label>
            <div class="relative">
              <input
                type="text"
                class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm"
                v-model="lastName"
                placeholder="Last Name"
              />
            </div>
          </div>
        </div>
        <div>
          <label for="email" class="sr-only">Email</label>
          <div class="relative">
            <input
              type="email"
              class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm"
              v-model="email"
              placeholder="Enter email"
            />
          </div>
        </div>
        <div>
          <label for="password" class="sr-only">Password</label>
          <div class="relative">
            <input
              type="password"
              class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm"
              v-model="password"
              placeholder="Enter password"
            />
          </div>
        </div>
        <div>
          <label for="confirm-password" class="sr-only">Confirm Password</label>
          <div class="relative">
            <input
              type="password"
              class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm"
              v-model="confirmPassword"
              placeholder="Confirm password"
            />
          </div>
        </div>
        <div class="flex items-center justify-center">
          <button
            class="bg-primary hover:bg-secondary text-neutral-content font-semibold text-white rounded-md px-20 py-2 hover:animate-pulse"
          >
            Register
          </button>
        </div>
        <p class="text-sm text-gray-500">
          <a class="underline" href="">already got an account</a>
        </p>
        <div class="flex items-center my-6">
          <hr class="flex-grow border-gray-300" />
          <span class="mx-4 text-gray-500">or sign in with</span>
          <hr class="flex-grow border-gray-300" />
        </div>
        <div class="flex justify-center">
          <button
            @click.prevent="signInWithGoogle"
            class="bg-secondary hover:bg-secondary text-neutral-content font-semibold text-white rounded-md px-20 py-2 hover:animate-pulse flex items-center justify-center"
          >
            <svg
              class="mr-2 -ml-1 w-4 h-4"
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="google"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 488 512"
            >
              <path
                fill="currentColor"
                d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
              ></path>
            </svg>
            Google
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  onAuthStateChanged,
} from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { useRouter } from "vue-router";

const firstName = ref("");
const lastName = ref("");
const email = ref("");
const password = ref("");
const confirmPassword = ref("");
const loading = ref(false); // Loading state
const router = useRouter();

const register = async () => {
  if (password.value !== confirmPassword.value) {
    alert("Passwords do not match");
    return;
  }

  const auth = getAuth();
  const firestore = getFirestore();

  try {
    loading.value = true; // Set loading state
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email.value,
      password.value
    );
    const user = userCredential.user;

    // Send verification email
    await sendEmailVerification(user); // Send verification email

    // Save additional user data in Firestore
    await setDoc(doc(firestore, "users", user.uid), {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      createdAt: new Date(),
    });

    console.log("Successfully registered and saved additional data");
    router.push("/verify-email"); // Redirect to verify-email page
  } catch (error) {
    console.error(error.code);
    alert(error.message);
    loading.value = false; // Reset loading state
  }
};

// Add listener for authentication state change
onAuthStateChanged(getAuth(), async (user) => {
  if (user) {
    // Check if email is verified
    if (user.emailVerified) {
      console.log("Email is verified, redirecting...");
      router.push("/feed"); // Redirect to feed page after verification
    }
  }
});
</script>
