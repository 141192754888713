<template>
  <div>
    <div class="flex justify-center items-center mb-8 w-full mx-auto">
      <span class="text-lg text-white font-semibold mr-2"></span>
      <div class="flex items-center text-white">
        <ul class="steps w-full">
          <li
            :class="{ step: true, 'step-primary': index < currentStep }"
            v-for="(step, index) in steps"
            :key="index"
          >
            {{ step }}
          </li>
        </ul>
      </div>
    </div>

    <div class v-if="currentStep === 0">
      <h2 class="text-lg font-semibold mb-4">
        What type of cleaning service do you require??
      </h2>

      <div class="flex justify-center items-center">
        <fieldset class="space-y-4 w-67">
          <legend class="sr-only">Delivery</legend>

          <div>
            <input
              type="radio"
              name="DeliveryOption"
              value="DeliveryStandard"
              id="DeliveryStandard"
              class="peer hidden [&:checked_+_label_svg]:block"
              checked
            />

            <label
              for="DeliveryStandard"
              class="flex cursor-pointer items-center justify-between rounded-lg border border-gray-100 bg-white p-4 text-base font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500"
            >
              <div class="flex items-center gap-2">
                <svg
                  class="hidden h-6 w-6 text-blue-600"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <!-- Your SVG path here -->
                </svg>

                <p class="text-gray-700 text-base">I need a deep clean...</p>
              </div>
            </label>
          </div>

          <div>
            <input
              type="radio"
              name="DeliveryOption"
              value="DeliveryPriority"
              id="DeliveryPriority"
              class="peer hidden [&:checked_+_label_svg]:block"
            />

            <label
              for="DeliveryPriority"
              class="flex cursor-pointer items-center justify-between rounded-lg border border-gray-100 bg-white p-4 text-base font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500"
            >
              <div class="flex items-center gap-2">
                <svg
                  class="hidden h-6 w-6 text-blue-600"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <!-- Your SVG path here -->
                </svg>

                <p class="text-gray-700 text-base">I need a small clean...</p>
              </div>
            </label>
          </div>

          <!-- New Button -->
          <div>
            <input
              type="radio"
              name="DeliveryOption"
              value="TestWithin14Days"
              id="TestWithin14Days"
              class="peer hidden [&:checked_+_label_svg]:block"
            />

            <label
              for="TestWithin14Days"
              class="flex cursor-pointer items-center justify-between rounded-lg border border-gray-100 bg-white p-4 text-base font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500"
            >
              <div class="flex items-center gap-2">
                <svg
                  class="hidden h-6 w-6 text-blue-600"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <!-- Your SVG path here -->
                </svg>

                <p class="text-gray-700 text-base">
                  I need an end of tenancy clean...
                </p>
              </div>
            </label>
          </div>

          <!-- End of New Button -->
        </fieldset>
      </div>
    </div>

    <div v-if="currentStep === 1">q</div>
    <button
      @click="currentStep--"
      class="mx-3 object-left bg-primary hover:bg-accent-focus hover:animate-pulse font-semibold text-white rounded-md px-4 py-2 mt-4"
    >
      Back
    </button>
    <button
      @click="currentStep++"
      class="object-left bg-primary hover:bg-accent-focus hover:animate-pulse font-semibold text-white rounded-md px-4 py-2 mt-4"
    >
      Next
    </button>
  </div>

  <div v-if="currentStep === 2"></div>

  <div v-if="currentStep === 3">
    <h2 class="text-lg font-semibold mb-4">Specifics</h2>
    <!-- Add your form fields for personal details here -->
  </div>

  <div v-if="currentStep === 4">
    <h2 class="text-lg font-semibold mb-4">Additional Info</h2>
    <!-- Add your form fields for personal details here -->
  </div>
</template>

<script>
export default {
  name: "SubmitInformation",
  components: {},
  data() {
    return {
      currentStep: 0,
      steps: ["Type", "Date/Time", "Specifics", "Additional Info"],
    };
  },
};
</script>

<style>
.steps li:not(:last-child) {
  margin-right: 1rem;
}

.dp__theme_light {
  --dp-background-color: #7d7259;
  --dp-text-color: #212121;
  --dp-hover-color: #dec75d;
  --dp-hover-text-color: white;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #ef9994;
  --dp-primary-text-color: #f8f5f5;
  --dp-secondary-color: #c0c4cc;

  --dp-menu-border-color: #ddd;
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #f3f3f3;
  --dp-scroll-bar-color: #959595;
  --dp-success-color: #76d275;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
  --dp-highlight-color: rgba(25, 118, 210, 0.1);
}
</style>
