<template lang="">
  <div>
    <navBar />
    <PostcodeForm />
  </div>
</template>
<script>
import navBar from "./nav/navBar";
import PostcodeForm from "./PostcodeForm.vue";
export default {
  components: {
    navBar,
    PostcodeForm,
  },
};
</script>
<style lang=""></style>
